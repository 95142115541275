import * as Yup from 'yup'
import React, { useState, useEffect } from 'react'
import { useRegistrationSchema } from '../../../common/validations/signup.validation'
import clsx from 'clsx'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { toastify } from '../../../common/customHooks/Toast'
import CustomLogoutHook from 'src/app/common/components/CustomLogoutHook'
import { type UserRegisterModel, type RTKAPIResponse } from '../core/_models'
import { totalEmployees, industryTypes } from '../../../common/utilities/constant'
import { statusCode, signupMessage } from '../../../common/utilities/constantsMessages'
import useYupValidationResolver from '../../../common/components/YupValidationResolver'
import { useLazyCompanyDetailQuery, useRegisterMutation } from '../../../redux/Slices/authApi'
import Spinner from '../../../common/components/Spinner'

import { FormattedMessage, useIntl } from 'react-intl'
export function Registration () {
  const navigate = useNavigate()
  const handleApiResponse = CustomLogoutHook()
  // This loading is for the ist time load of signup in which we need to hide industry and employee type
  const [loading, setLoading] = useState(true)
  const [org_id, setOrgId] = useState('')
  const [compState, setCompState] = useState(false)
  const intl = useIntl()
  const registrationSchema = useRegistrationSchema()
  const modifiedSchema = !org_id
    ? registrationSchema
    : Yup.object(registrationSchema.fields).omit(['company_name'])
  const resolver = useYupValidationResolver(modifiedSchema)
  const location = useLocation()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setError,
    clearErrors
  } = useForm<UserRegisterModel>({ resolver, mode: 'onChange' })
  const [registerUser, { isError: isRegisterError, error: RegisterError, isLoading: isSignupLoading }] = useRegisterMutation()

  const [getCompanyDetail] = useLazyCompanyDetailQuery()
  const handleBlur = async () => {
    const formValues = getValues()
    try {
      const params = {
        name: formValues.company_name
      }

      const res = await getCompanyDetail(params, true)
      if (res.data) {
        setCompState(true)
        setError('company_name', { type: 'manual', message: 'Company Name Already Exists' })
      }
      if (res.error) {
        setCompState(false)
        clearErrors('company_name')
      }
    } catch (error: any) {
      setCompState(false)
      clearErrors('company_name')
    }
  }
  const handleSubmitRegister = async () => {
    if (compState) {
      setError('company_name', {
        type: 'manual',
        message: intl.formatMessage({ id: 'VALIDATION.COMPANY.REQUIRED' })
      })
      return
    }
    const formValues = getValues()
    const full_name = formValues?.fullname?.trim()
    const email = formValues?.email?.trim()
    const password = formValues?.password?.trim()
    const personal_phone = formValues?.personal_phone
    let company_name = ''
    if (formValues.company_name) company_name = formValues.company_name.trim()
    const industry_type = formValues?.industry_types
    const total_employee = formValues?.total_employee
    try {
      const result: RTKAPIResponse = (await registerUser({
        full_name,
        email,
        password,
        personal_phone,
        ...(!org_id ? { industry_type, total_employee, company_name, org_id } : { org_id })
      })) as RTKAPIResponse
      if (result?.data?.status_code === statusCode.create) {
        toastify({
          type: 'success',
          message: signupMessage.success
        })
        navigate('/auth')
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const fetchData = async (domain: string) => {
    try {
      const params = {
        domain
      }
      const compData: RTKAPIResponse = (await getCompanyDetail(params, true)) as RTKAPIResponse
      const result = compData.data
      setLoading(false)
      if (result?.status_code === statusCode.success) {
        setOrgId(result?.data?.id)
      }
    } catch (err) {
      setLoading(false)
    }
  }

  useEffect(() => {
    const url = window.location.href
    const parsedUrl = new URL(url)
    const domain = parsedUrl.hostname
    fetchData(domain)
  }, [location])

  // useEffect(() => {
  //   const typedRegisterError: RTKAPIResponse = RegisterError as RTKAPIResponse
  //   const message = typedRegisterError?.data?.error?.message
  //   if (message) {
  //     toastify({ type: 'error', message })
  //   }
  // }, [isRegisterError])

  useEffect(() => {
    handleApiResponse(RegisterError as RTKAPIResponse, isRegisterError)
  }, [isRegisterError])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <>
      {loading
        ? (
        <Spinner />
          )
        : (
        <form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={handleSubmit(handleSubmitRegister)}
        >
          <div className='text-center mb-11'>
            <h1 className='text-dark fw-bolder mb-3'>
              <FormattedMessage id='AUTH.GENERAL.SIGNUP_BUTTON' />
            </h1>
            <div className='text-gray-500 fw-semibold fs-6'>
              {' '}
              <FormattedMessage id='AUTH.GENERAL.TEXT' />
            </div>
          </div>
          <div className='row g-3 mb-9'>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                  className='h-15px me-3'
                />
                <FormattedMessage id='AUTH.GENERAL.GOOGLE' />
              </a>
            </div>
            <div className='col-md-6'>
              <a
                href='#'
                className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
              >
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                  className='theme-light-show h-15px me-3'
                />
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                  className='theme-dark-show h-15px me-3'
                />
                <FormattedMessage id='AUTH.GENERAL.APPLE' />
              </a>
            </div>
          </div>

          <div className='separator separator-content my-14'>
            <span className='w-125px text-gray-500 fw-semibold fs-7'>
              <FormattedMessage id='AUTH.GENERAL.EMAIL' />
            </span>
          </div>
          {!org_id && (
            <div className='fv-row mb-8'>
              {/* begin::Form group Lastname */}
              <label className='form-label fw-bolder text-dark fs-6'>
                <FormattedMessage id='AUTH.SIGNUP.TEXT.COMPANY' />
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'AUTH.SIGNUP.TEXT.COMPANY' })}
                type='text'
                autoComplete='off'
                id='company_name'
                className={clsx('form-control bg-transparent')}
                {...register('company_name')}
                onBlur={handleBlur}
              />
              {errors?.company_name?.message && (
                <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                  {errors?.company_name?.message}
                </p>
              )}
            </div>
          )}
          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id='AUTH.SIGNUP.TEXT.NAME' />
            </label>
            <input
              className={clsx('form-control bg-transparent')}
              placeholder={intl.formatMessage({ id: 'AUTH.SIGNUP.TEXT.NAME' })}
              type='text'
              autoComplete='off'
              {...register('fullname', { required: true })}
            />
            {errors?.fullname?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors?.fullname?.message}
              </p>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id='AUTH.GENERAL.TEXT.EMAIL' />
            </label>
            <input
              className={clsx('form-control bg-transparent')}
              placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TEXT.EMAIL' })}
              type='email'
              autoComplete='off'
              {...register('email', { required: true })}
            />
            {errors?.email?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors?.email?.message}
              </p>
            )}
          </div>

          <div className='fv-row mb-8'>
            <label className='form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id='AUTH.SIGNUP.TEXT.PHONE' />
            </label>
            <input
              className={clsx('form-control bg-transparent')}
              placeholder={intl.formatMessage({ id: 'AUTH.SIGNUP.TEXT.PHONE' })}
              type='text'
              autoComplete='off'
              {...register('personal_phone', { required: true })}
            />
            {errors?.personal_phone?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors?.personal_phone?.message}
              </p>
            )}
          </div>
          {!org_id && (
            <>
              <div className='fv-row mb-8'>
                <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {' '}
                    <FormattedMessage id='AUTH.SIGNUP.TEXT.EMPLOYEES' />
                  </label>
                  <div className='position-relative mb-3'>
                    <select
                      className={clsx('form-select bg-transparent ')}
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('total_employee', { required: true })}
                    >
                      {totalEmployees.map((employee, index) => (
                        <option
                          key={index}
                          value={employee}
                          style={{ background: 'transparent', padding: '8px' }}
                        >
                          {employee}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className='fv-row mb-8'>
                <div className='mb-1'>
                  <label className='form-label fw-bolder text-dark fs-6'>
                    {' '}
                    <FormattedMessage id='AUTH.SIGNUP.TEXT.INDUSTRY' />
                  </label>
                  <div className='position-relative mb-3'>
                    <select
                      className={clsx('form-select bg-transparent ')}
                      data-control='select2'
                      data-placeholder='Latest'
                      data-hide-search='true'
                      {...register('industry_types', { required: true })}
                    >
                      {industryTypes.map((industry, index) => (
                        <option
                          key={index}
                          value={industry}
                          style={{ background: 'transparent', padding: '8px' }}
                        >
                          {industry}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </>
          )}
          <div className='fv-row mb-8' data-kt-password-meter='true'>
            <div className='mb-1'>
              <label className='form-label fw-bolder text-dark fs-6'>
                {' '}
                <FormattedMessage id='AUTH.GENERAL.TEXT.PASSWORD' />
              </label>
              <div className='position-relative mb-3'>
                <input
                  className={clsx('form-control bg-transparent')}
                  type='password'
                  placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TEXT.PASSWORD' })}
                  autoComplete='off'
                  {...register('password', { required: true })}
                />
                {errors?.password?.message && (
                  <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                    {errors?.password?.message}
                  </p>
                )}
              </div>
            </div>
          </div>

          <div className='fv-row mb-5'>
            <label className='form-label fw-bolder text-dark fs-6'>
              <FormattedMessage id='AUTH.GENERAL.TEXT.CPASSWORD' />
            </label>
            <input
              className={clsx('form-control bg-transparent')}
              type='password'
              placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TEXT.CPASSWORD' })}
              autoComplete='off'
              {...register('confirm_password', { required: true })}
            />
            {errors?.confirm_password?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors?.confirm_password?.message}
              </p>
            )}
          </div>
          {/* <div className='fv-row mb-8'>
            <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
              <input
                className='form-check-input'
                type='checkbox'
                id='accept_terms'
                {...register('acceptTerms', {required: true})}
              />
              <span>
                <FormattedMessage id='AUTH.SIGNUP.TEXT.ACCEPT' />
              </span>
            </label>
            {errors?.accept_terms?.message && (
              <p className='error-message' style={{color: 'red', fontSize: 13}}>
                {errors.accept_terms.message}
              </p>
            )}
          </div> */}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              // disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {isSignupLoading
                ? (
                 <span className='indicator-progress' style={{ display: 'block' }}>
                 <FormattedMessage id='AUTH.GENERAL.TEXT.WAIT' />
                 <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
               </span>
                  )
                : (
                <span className='indicator-label'>
                  <FormattedMessage id='AUTH.GENERAL.SUBMIT.BUTTON' />
                </span>
                  )}
              {/* <span className='indicator-label'>
                <FormattedMessage id='AUTH.GENERAL.SUBMIT.BUTTON' />
              </span> */}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                <FormattedMessage id='AUTH.GENERAL.CANCEL.BUTTON' />
              </button>
            </Link>
          </div>
        </form>
          )}
    </>
  )
}
