import * as yup from 'yup'
import { YupResolver } from '../../modules/auth/core/_models'
import { emailFormat } from '../utilities/constant'
import { useIntl } from 'react-intl'
// export const loginSchema: yup.Schema<YupResolver> = yup.object({
//   email: yup.string()
//   .matches(emailFormat, 'Email is not valid')
//     .min(3, 'Minimum 3 symbols')
//     .max(50, 'Maximum 50 symbols')
//     .required('Email is required'),
//   password: yup
//     .string()
//     .min(4, 'password should be of minimum 4 characters length')
//     .required('password is required'),
// })
export const useLoginSchema = () => {
  const intl = useIntl()

  const loginSchema: yup.Schema<{ email: string, password: string }> = yup.object({
    email: yup
      .string()
      .matches(emailFormat, useIntl().formatMessage({ id: 'VALIDATION.EMAIL.TEXT.INVALID' }))
      .required(useIntl().formatMessage({ id: 'VALIDATION.EMAIL.TEXT.REQUIRED' })),
    password: yup
      .string()
      .min(4, useIntl().formatMessage({ id: 'VALIDATION.PASSWORD.TEXT.LENGTH' }))
      .required(useIntl().formatMessage({ id: 'VALIDATION.PASSWORD.TEXT.REQUIRED' }))
  })
  return loginSchema
}
