import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { GetTokenFromLocalStorage } from '../../common/utilities/utility'
import {
  type UserLoginModel,
  type UserRegisterModel,
  type UpdateUserProfileModel,
  type UpdateOrganizationModel,
  type GetUserProfileModel,
  type TwoFAModel,
  type VoiceModalObjModal,
  type CreateMenuParams
} from '../../modules/auth'
const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({ baseUrl: BASE_URL }),
  tagTypes: [
    'GetComp',
    'GetNumberModule',
    'GetProfile',
    'GetUserModule',
    'GetGroupModule',
    'GetQueueModule',
    'GetMainNumberModule',
    'GetBlacklistModule',
    'GetMenuModule',
    'GetSoundFileModule',
    'GetVoiceModule',
    'GetDeviceModule',
    'GetTimeOfDayModule',
    'GetCallflow'
  ],
  endpoints: (builder: any) => ({
    login: builder.mutation({
      query: ({ email, password, ID }: UserLoginModel) => ({
        url: '/login',
        method: 'POST',
        body: { email, password, ID }
      }),
      transformResponse: (response: any, meta: any) => {
        let authToken
        if (meta.response.headers.get('x_auth_token')) {
          authToken = meta?.response?.headers?.get('x_auth_token')
        }
        const modifiedResponse = { ...response, authToken }
        return modifiedResponse
      },
      onError: (error: any) => {
        // Handle the error here
        console.error('Error creating user:', error)
      }
    }),
    loginUsingToken: builder.mutation({
      query: (token: string) => ({
        url: '/account/profile',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }),
      transformResponse: (response: any) => {
        return response
      }
    }),
    companyDetail: builder.query({
      query: (params: string) => ({
        url: '/organization/detail',
        params
      }),
      providesTags: ['GetComp']
    }),
    Register: builder.mutation({
      query: ({
        full_name,
        email,
        password,
        personal_phone,
        company_name,
        industry_type,
        total_employee,
        org_id
      }: UserRegisterModel) => ({
        url: '/signup',
        method: 'POST',
        headers: {
          Org_id: org_id
        },
        body: {
          full_name,
          email,
          password,
          personal_phone,
          company_name,
          industry_type,
          total_employee
        }
      }),
      invalidatesTags: ['GetComp']
    }),
    getOrgInfo: builder.query({
      query: (id: string) => ({
        url: `organization/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetComp']
    }),
    ResendVerify: builder.mutation({
      query: (value: string) => ({
        url: '/resend-verify',
        method: 'PUT',
        body: value
      })
    }),
    ForgetPassword: builder.mutation({
      query: (value: string) => ({
        url: '/forgotpassword',
        method: 'POST',
        body: value
      })
    }),

    updateOrganizationDetail: builder.mutation({
      query: ({
        name,
        logo,
        industry_type,
        total_employee,
        primary_color,
        secondary_color
      }: UpdateOrganizationModel) => {
        const formData = new FormData()
        formData.append('name', name)
        formData.append('logo', logo)
        formData.append('industry_type', industry_type)
        formData.append('total_employee', total_employee)
        formData.append('primary_color', primary_color)
        formData.append('secondary_color', secondary_color)
        return {
          url: '/organization/profile',
          method: 'PUT',
          body: formData,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetComp']
    }),

    updateProfile: builder.mutation({
      query: ({ picture, full_name, personal_phone, token }: UpdateUserProfileModel) => {
        const formData = new FormData()
        formData.append('picture', picture)
        formData.append('full_name', full_name)
        formData.append('personal_phone', personal_phone)
        const jwt_token = token || GetTokenFromLocalStorage()
        return {
          url: '/account/profile',
          method: 'PUT',
          body: picture && full_name && personal_phone ? formData : {},
          headers: {
            Authorization: `Bearer ${jwt_token}`
          }
        }
      },
      invalidatesTags: ['GetProfile']
    }),

    getProfile: builder.query({
      query: ({ token }: GetUserProfileModel) => {
        const jwt_token = token || GetTokenFromLocalStorage()
        return {
          url: '/account/profile',
          method: 'GET',
          headers: {
            Authorization: `Bearer ${jwt_token}`
          }
        }
      },
      providesTags: ['GetProfile']
    }),

    generateOtp: builder.mutation({
      query: ({ user_id }: TwoFAModel) => ({
        url: '/generateOtp',
        method: 'POST',
        body: { user_id },
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    verifyOtp: builder.mutation({
      query: ({ user_id, otp_token }: TwoFAModel) => ({
        url: '/verifyOtp',
        method: 'POST',
        body: { user_id, otp_token },
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    disable2FA: builder.mutation({
      query: ({ user_id }: TwoFAModel) => ({
        url: '/disable2FA',
        method: 'POST',
        body: { user_id },
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    validateOtp: builder.mutation({
      query: ({ user_id, otp_token }: TwoFAModel) => ({
        url: '/validateOtp',
        method: 'POST',
        body: { user_id, otp_token }
      })
    }),

    unVerifyOtp: builder.mutation({
      query: ({ user_id, jwt_token }: { user_id: string, jwt_token: string }) => ({
        url: '/unVerifyOtp',
        method: 'POST',
        body: { user_id },
        headers: {
          Authorization: `Bearer ${jwt_token}`
        }
      })
    }),

    getNumbers: builder.query({
      query: (params = {}) => ({
        url: '/number',
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetNumberModule']
    }),
    getGroups: builder.query({
      query: (params = {}) => ({
        url: '/group',
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetGroupModule']
    }),

    updateGroup: builder.mutation({
      query: (group_data: any) => {
        const id = group_data.id
        return {
          url: `/group/${id}`,
          method: 'PUT',
          body: group_data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetGroupModule']
    }),

    createGroup: builder.mutation({
      query: (group_data: any) => ({
        url: '/group',
        method: 'POST',
        body: group_data,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetGroupModule']
    }),

    deleteGroup: builder.mutation({
      query: (id: string) => ({
        url: `/group/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetGroupModule']
    }),
    searchNumbersInTelnix: builder.query({
      query: (params: {}) => ({
        url: '/number/telnyx-numbers',
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    deleteNumber: builder.mutation({
      query: (id: string) => ({
        url: `/number/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetNumberModule']
    }),
    MainNumberUpdate: builder.mutation({
      query: (params: {}) => ({
        url: '/number/set-main',
        method: 'PATCH',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetNumberModule', 'GetMainNumberModule']
    }),
    buyNumber: builder.mutation({
      query: (params: {}) => ({
        url: '/number',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetNumberModule']
    }),

    getMainNumber: builder.query({
      query: () => ({
        url: '/number/get-main',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetMainNumberModule']
    }),

    getAllDevices: builder.query({
      query: (params = {}) => ({
        url: '/device',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      }),
      providesTags: ['GetDeviceModule']
    }),

    getBlacklistedNumbers: builder.query({
      query: (params = {}) => ({
        url: '/blacklist',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      }),
      providesTags: ['GetBlacklistModule']
    }),

    addBlacklistedNumbers: builder.mutation({
      query: (numbers: any) => ({
        url: '/blacklist',
        method: 'POST',
        body: numbers,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetBlacklistModule']
    }),

    removeBlacklistedNumber: builder.mutation({
      query: (number: any) => ({
        url: '/blacklist',
        method: 'PUT',
        body: number,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetBlacklistModule']
    }),

    getUsers: builder.query({
      query: (params = {}) => ({
        url: '/user',
        method: 'GET',
        params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetUserModule']
    }),

    updateUser: builder.mutation({
      query: (params: any) => {
        const { id, ...data } = params
        return {
          url: `/user/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetUserModule']
    }),

    addQueue: builder.mutation({
      query: (params: any) => {
        return {
          url: '/module/queue',
          method: 'POST',
          body: params,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetQueueModule']
    }),

    addUser: builder.mutation({
      query: (params: any) => {
        return {
          url: '/user',
          method: 'POST',
          body: params,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetUserModule']
    }),

    getQueues: builder.query({
      query: (params = {}) => ({
        url: '/module/queue',
        method: 'GET',
        params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetQueueModule']
    }),

    deleteQueue: builder.mutation({
      query: (id: string) => ({
        url: `/module/queue/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetQueueModule']
    }),

    updateMenu: builder.mutation({
      query: (params: CreateMenuParams) => {
        const { id, ...data } = params
        return {
          url: `/module/menu/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetMenuModule', 'GetSoundFileModule']
    }),

    updateQueue: builder.mutation({
      query: (queue_data: any) => ({
        url: `/module/queue/${queue_data.id}`,
        method: 'PUT',
        body: queue_data,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        invalidatesTags: ['GetQueueModule']
      })
    }),

    deleteUser: builder.mutation({
      query: (id: string) => ({
        url: `/user/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetUserModule']
    }),
    getDevicesByUserId: builder.query({
      query: (id: string) => ({
        url: `user/${id}/devices`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),

    updateDoNotDisturb: builder.mutation({
      query: (params: any) => {
        const { id, ...data } = params
        return {
          url: `/user/${id}/donot-disturb`,
          method: 'PATCH',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetUserModule']
    }),

    updateCallForwarding: builder.mutation({
      query: (params: any) => {
        const { id, ...data } = params
        return {
          url: `/user/${id}/call-forward`,
          method: 'PATCH',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetUserModule']
    }),

    getMenus: builder.query({
      query: (params = {}) => ({
        url: '/module/menu',
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetMenuModule']
    }),

    updateFindMeFollowMe: builder.mutation({
      query: (params: any) => {
        const { id, ...data } = params
        return {
          url: `/user/${id}/find-me-follow-me`,
          method: 'PATCH',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetUserModule']
    }),

    addMenu: builder.mutation({
      query: (params: CreateMenuParams) => ({
        url: '/module/menu',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetMenuModule', 'GetSoundFileModule']
    }),

    deleteMenu: builder.mutation({
      query: (id: string) => ({
        url: `/module/menu/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetMenuModule', 'GetSoundFileModule']
    }),

    getSoundFile: builder.query({
      query: (params = {}) => ({
        url: '/soundfile',
        method: 'GET',
        params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetSoundFileModule']
    }),
    addSoundFile: builder.mutation({
      query: (formData: any) => ({
        url: '/soundfile',
        method: 'POST',
        body: formData,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetSoundFileModule']
    }),

    deleteSoundFile: builder.mutation({
      query: (id: string) => ({
        url: `/soundfile/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetSoundFileModule']
    }),
    getAllVoicemails: builder.query({
      query: (params = {}) => ({
        url: '/voicemailbox',
        params,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetVoiceModule']
    }),
    addVoiceMailBox: builder.mutation({
      query: (params: VoiceModalObjModal) => ({
        url: '/voicemailbox',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetVoiceModule']
    }),
    updateVoiceMailBox: builder.mutation({
      query: (params: VoiceModalObjModal) => {
        const { id, ...restParams } = params
        return {
          url: `/voicemailbox/${id}`,
          method: 'PUT',
          body: restParams,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetVoiceModule']
    }),

    deleteVoice: builder.mutation({
      query: (id: string) => ({
        url: `/voicemailbox/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetVoiceModule']
    }),

    ChangePassword: builder.mutation({
      query: (values: any) => ({
        url: '/account/changepassword',
        method: 'PUT',
        body: values,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),

    enableDevice: builder.mutation({
      query: (params: any) => ({
        url: `/device/${params.id}/status`,
        method: 'PATCH',
        body: { enable: params.enable },
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetDeviceModule']
    }),

    updateDevice: builder.mutation({
      query: (params: any) => {
        const { id, ...data } = params
        return {
          url: `/device/${id}`,
          method: 'PUT',
          body: data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetDeviceModule']
    }),
    addDevice: builder.mutation({
      query: (params: any) => {
        return {
          url: '/device',
          method: 'POST',
          body: params,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetDeviceModule']
    }),
    deleteDevice: builder.mutation({
      query: (id: string) => ({
        url: `/device/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetDeviceModule']
    }),

    getTimeOfDay: builder.query({
      query: (params = {}) => ({
        url: '/module/time-condition',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      }),
      providesTags: ['GetTimeOfDayModule']
    }),

    addTimeOfDay: builder.mutation({
      query: (params: any) => ({
        url: '/module/time-condition',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetTimeOfDayModule']
    }),

    updateTimeOfDay: builder.mutation({
      query: (params: any) => ({
        url: `/module/time-condition/${params.id}`,
        method: 'PUT',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetTimeOfDayModule']
    }),

    deleteTimeOfDay: builder.mutation({
      query: (id: string) => ({
        url: `/module/time-condition/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetTimeOfDayModule']
    }),

    getCallflows: builder.query({
      query: (params = {}) => ({
        url: '/dialplan',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      }),
      providesTags: ['GetCallflow']
    }),
    deleteCallflow: builder.mutation({
      query: (id: string) => ({
        url: `/dialplan/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    getCDR: builder.query({
      query: (params = {}) => ({
        url: '/cdr',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      })
    }),
    getCDRExport: builder.query({
      query: (params = {}) => ({
        url: '/cdr/export',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      })
    }),
    getFlowReport: builder.query({
      query: (params = {}) => ({
        url: '/cdr-flow-report',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        },
        params
      })
    }),
    getFlowReportNumbers: builder.query({
      query: () => ({
        url: '/cdr/callee',
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    getNumbersByIDs: builder.mutation({
      query: (params: any) => ({
        url: '/number/by-ids',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    getModuleDetails: builder.mutation({
      query: (params = {}) => ({
        url: '/module/detail',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      })
    }),
    getCallflowById: builder.query({
      query: (id: string) => ({
        url: `/dialplan/${id}`,
        method: 'GET',
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      providesTags: ['GetCallflow']
    }),
    addNewCallflowAPI: builder.mutation({
      query: (params = {}) => ({
        url: '/dialplan',
        method: 'POST',
        body: params,
        headers: {
          Authorization: `Bearer ${GetTokenFromLocalStorage()}`
        }
      }),
      invalidatesTags: ['GetCallflow']
    }),
    updateCallflow: builder.mutation({
      query: (callflow_data: any) => {
        const id = callflow_data.id
        return {
          url: `/dialplan/${id}`,
          method: 'PUT',
          body: callflow_data,
          headers: {
            Authorization: `Bearer ${GetTokenFromLocalStorage()}`
          }
        }
      },
      invalidatesTags: ['GetCallflow']
    })
  })
})
export const {
  useLoginMutation,
  useLoginUsingTokenMutation,
  useLazyCompanyDetailQuery,
  useRegisterMutation,
  useLazyGetOrgInfoQuery,
  useResendVerifyMutation,
  useForgetPasswordMutation,
  useLazyGetUsersQuery,
  useUpdateOrganizationDetailMutation,
  useUpdateProfileMutation,
  useGenerateOtpMutation,
  useVerifyOtpMutation,
  useDisable2FAMutation,
  useValidateOtpMutation,
  useUnVerifyOtpMutation,
  useLazyGetProfileQuery,
  useLazyGetNumbersQuery,
  useLazyGetGroupsQuery,
  useLazySearchNumbersInTelnixQuery,
  useUpdateGroupMutation,
  useCreateGroupMutation,
  useDeleteNumberMutation,
  useMainNumberUpdateMutation,
  useBuyNumberMutation,
  useDeleteGroupMutation,
  useLazyGetMainNumberQuery,
  useLazyGetAllDevicesQuery,
  useUpdateUserMutation,
  useLazyGetBlacklistedNumbersQuery,
  useAddUserMutation,
  useDeleteUserMutation,
  useAddBlacklistedNumbersMutation,
  useRemoveBlacklistedNumberMutation,
  useLazyGetDevicesByUserIdQuery,
  useUpdateDoNotDisturbMutation,
  useUpdateCallForwardingMutation,
  useUpdateFindMeFollowMeMutation,
  useLazyGetQueuesQuery,
  useAddQueueMutation,
  useDeleteQueueMutation,
  useLazyGetSoundFileQuery,
  useUpdateQueueMutation,
  useLazyGetMenusQuery,
  useUpdateMenuMutation,
  useAddMenuMutation,
  useDeleteMenuMutation,
  useAddSoundFileMutation,
  useDeleteSoundFileMutation,
  useLazyGetAllVoicemailsQuery,
  useUpdateVoiceMailBoxMutation,
  useAddVoiceMailBoxMutation,
  useDeleteVoiceMutation,
  useChangePasswordMutation,
  useEnableDeviceMutation,
  useUpdateDeviceMutation,
  useAddDeviceMutation,
  useDeleteDeviceMutation,
  useLazyGetTimeOfDayQuery,
  useAddTimeOfDayMutation,
  useUpdateTimeOfDayMutation,
  useDeleteTimeOfDayMutation,
  useLazyGetCallflowsQuery,
  useDeleteCallflowMutation,
  useLazyGetCDRQuery,
  useLazyGetCDRExportQuery,
  useLazyGetFlowReportQuery,
  useLazyGetFlowReportNumbersQuery,
  useGetNumbersByIDsMutation,
  useGetModuleDetailsMutation,
  useLazyGetCallflowByIdQuery,
  useAddNewCallflowAPIMutation,
  useUpdateCallflowMutation
} = authApi
