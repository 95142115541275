import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { toastify } from '../../../common/customHooks/Toast'
import { type RTKAPIResponse, type VerifyModal } from '../core/_models'
import { statusCode, resendVerifyMessage } from '../../../common/utilities/constantsMessages'
import { useResendVerifyMutation } from '../../../redux/Slices/authApi'

const MyModal: React.FC<VerifyModal> = ({ showModal, handleCloseModal, email }) => {
  const [
    resendVerify,
    { isError: isErrorVerify, error: ErrorVerify }
  ] = useResendVerifyMutation()
  const handleVerifyEmail = async () => {
    try {
      const value = { email }
      const result: RTKAPIResponse = (await resendVerify(value)) as RTKAPIResponse
      if (result?.data?.status_code === statusCode.success) {
        toastify({
          type: 'success',
          message: result.data?.message + resendVerifyMessage.toastMessage
        })
      }
    } catch (err: any) {}
    handleCloseModal()
  }
  useEffect(() => {
    const typedVerifyError: RTKAPIResponse = ErrorVerify as RTKAPIResponse
    const message = typedVerifyError?.data?.error?.message
    if (message) {
      toastify({ type: 'error', message })
    }
  }, [ErrorVerify])
  return (
    <Modal show={showModal} onHide={handleCloseModal} centered>
      <Modal.Header closeButton>
        <Modal.Title>Verify Email</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className='fs-3'>
          We kindly request you to verify your email address as it has not been confirmed yet. To
          proceed further, please click the button below to initiate the email verification process.
          An email containing instructions will be sent to you promptly.
        </p>
        <Button onClick={handleVerifyEmail} className='float-end mt-2'>
          Verify Email
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default MyModal
