export const numberRegex =
  /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
export const numberRegexWithoutHyphen =
/^(?:(?:\+?1\s*)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*)([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/
export const industryTypes = ['IT', 'Finance', 'Energy', 'Media']
export const BASE_URL = process.env.REACT_APP_API_BASE_URL
export const TIMEOUT_VALUE = 60000
export const USER_NOT_EXIST = 'User Does not Exist in this organization'
export const totalEmployees = ['1-10', '11-50', '51-100']
export const emailFormat = /\S+@\S+\.\S+/
export const dynamicKeyRegex = /^numbers\[\d+\]$/
export const CALL_FLOW = 'Call Flow'
export const CALL_FLOW_ID = 'callflow'
export const MARKS = [1, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]
export const DEVICE = 'Device'
export const PLAY_MEDIA = 'Play Media'
export const MENU = 'Menu'
export const USER = 'User'
export const RING_GROUP = 'Ring Group'
export const QUEUE = 'Queue'
export const VOICE_MAIL = 'Voice Mail'
export const TIME_OF_DAY = 'Time / Day'
export const RECORD_START = ' Record Start'
export const RECORD_STOP = ' Record Stop'
export const MAIN_MENU = ' Main Menu'
export const REPEAT_MENU = ' Repeat Menu'
export const specialModuleType = {
  queue: 0,
  device: 1,
  user: 2,
  ring_group: 3,
  voicemail: 4,
  record_start: 5,
  record_stop: 6,
  play_media: 7,
  TIME_OF_DAY: 8,
  repeat_menu: 10,
  main_menu: 11
}
export const DEVICE_ID = 'dial-base'
export const PLAY_MEDIA_ID = 'play-base'
export const MENU_ID = 'menu-base'
export const USER_ID = 'user-base'
export const RING_GROUP_ID = 'ring-group-base'
export const QUEUE_ID = 'queue-base'
export const VOICE_MAIL_ID = 'voice-box'
export const TIME_OF_DAY_ID = 'time-of-day'
export const RECORD_START_ID = 'recording-start'
export const RECORD_STOP_ID = 'recordering-stop'
export const REPEAT_MENU_ID = 'repeat-menu'
export const MAIN_MENU_ID = 'main-menu'
