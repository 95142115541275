import { createIntl, createIntlCache } from 'react-intl'

import translationEN from './_metronic/i18n/messages/en.json'
import translationAR from './_metronic/i18n/messages/ar.json'

const messages: Record<string, any> = {
  en: translationEN,
  ar: translationAR
}

const cache = createIntlCache()

export const detectLanguage = () => {
  const userLanguage = navigator.language.split('-')[0] // Get the user's preferred language
  return userLanguage || 'en' // Default to English if the user's language is not available
}

export const intl = createIntl(
  {
    locale: detectLanguage(),
    messages: messages[detectLanguage()]
  },
  cache
)
