// import {createTheme} from '@mui/material'
// import createBreakpoints from '@mui/system/createTheme/createBreakpoints'

import palette from './palette'

// const breakpoints = createBreakpoints({})

const { gray, primary, common } = palette

const theme = {
  typography: {
    fontFamily: "'Roboto', 'sans-serif'",
    h1: {
      fontSize: 48,
      fontWeight: 700,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
      marginBottom: 10,
      color: gray[600],
      fontFamily: "'Roboto', 'sans-serif'"
    },
    h2: {
      fontSize: 44,
      fontWeight: 700,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
      marginBottom: 10,
      color: gray[600],
      fontFamily: "'Roboto', 'sans-serif'"
    },
    h3: {
      fontSize: 26,
      fontWeight: 500,
      lineHeight: 1.25,
      letterSpacing: '-0.01562em',
      marginBottom: 10,
      color: gray[600],
      fontFamily: "'Roboto', 'sans-serif'"
    },
    h4: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
      marginBottom: 10,
      fontFamily: "'Roboto', 'sans-serif'",
      color: gray[600]
    },
    h5: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
      fontFamily: "'Roboto', 'sans-serif'",
      marginBottom: 10,
      color: gray[600]
    },
    subtitle1: {
      fontWeight: 400,
      fontSize: 18,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
      color: gray[600]
    },
    subtitle2: {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
      color: gray[600]
    },
    body1: {
      fontWeight: 400,
      fontSize: 15,
      lineHeight: 1.5,
      color: gray[600]
    },
    small: {
      fontWeight: 300,
      fontSize: 12,
      lineHeight: 1,
      color: '#898d91'
    },
    mediumSmall: {
      fontWeight: 300,
      fontSize: 16,
      lineHeight: 1,
      color: '#898d91'
    }
  },
  palette: {
    primary: {
      main: primary.dark,
      appBackground: primary.appBackground,
      sidebarBackground: primary.sidebarBackground
    },
    background: {
      blue: primary.dark,
      blueMedium: primary.medium,
      blueMediumLight: primary.mediumLight,
      blueLight: primary.light,
      blueExtraLight: primary.extraLight,
      orangeLight: palette.orange[100],
      orangeMediumLight: palette.orange[200],
      orangeDark: palette.orange[300],
      orangeMediumDark: palette.orange[300],
      skyblue: palette.skyblue[100],
      skyblueDark: palette.skyblue[300],
      pinkLight: palette.pink[100],
      pinkDark: palette.pink[200],
      lightBlue1: palette.common.blue,
      lightBlue: palette.common.lightBlue,
      lightGreen: palette.common.lightGreen,
      iconEnable: palette.common.iconEnbale,
      iconDisable: palette.common.iconDisable,
      iconDisable1: palette.common.iconDisable1,
      white: palette.common.white,
      black: palette.common.black,
      success: palette.common.success,
      danger: palette.common.danger,
      lightGray: common.lightGray,
      lightTinyBlue: common.lightTinyBlue,
      shadedGrey: common.shadedGrey,
      saveBtn: common.saveBtn
    },
    icon: {
      white: common.white,
      blue: primary.dark,
      blueMedium: primary.medium,
      blueLight: primary.light,
      lightBlack: common.lightBlack,
      lightGray: common.lightGray,
      lightDanger: common.lightDanger,
      danger: palette.common.danger
    },

    typography: {
      black: common.black,
      blue: primary.dark,
      blue0: primary.extraLight,
      blueMedium: primary.medium,
      white: common.white,
      gray: common.gray
    },
    contrastThreshold: 3,
    tonalOffset: 0.2
  },
  padding: {
    section: '0 50px'
  }
}

export default theme
