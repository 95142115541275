import React, { useEffect, useState } from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import clsx from 'clsx'
import { Link, useLocation } from 'react-router-dom'
import {
  useLazyGetProfileQuery,
  useUpdateProfileMutation,
  useValidateOtpMutation
} from '../../../../../redux/Slices/authApi'
import { statusCode } from '../../../../../common/utilities/constantsMessages'
import { toastify } from '../../../../../common/customHooks/Toast'
import { type RTKAPIResponse, type TextAlign, type UserModel, type YupResolver, useAuth } from '../../../../auth'
import useYupValidationResolver from '../../../../../common/components/YupValidationResolver'
import { TwoFASchema } from '../../../../../common/validations/2FA.validation'

import { SetTokenToLocalStorage } from '../../../../../common/utilities/utility'

const Validate2faPage = () => {
  const location: any = useLocation()
  const jwt_token = location?.state?.token

  const resolver = useYupValidationResolver(TwoFASchema)
  const [validateOtp, { isLoading }] = useValidateOtpMutation()
  const [getUserProfile, { isLoading: isGetUserLoading }] = useLazyGetProfileQuery()
  const { setCurrentUser } = useAuth()
  const [ID, setID] = useState('')
  const {
    handleSubmit,
    setFocus,
    register,
    formState: { errors }
  } = useForm({ resolver })

  useEffect(() => {
    getUserProfile({ token: jwt_token }).then((resp: any) => {
      const result: RTKAPIResponse = resp as RTKAPIResponse
      if (result?.data?.data?.id) {
        setID(result?.data?.data?.id)
      }
    })
  }, [])

  useEffect(() => {
    setFocus('otp')
  }, [setFocus])

  const validate2fa = async (otp_token?: string) => {
    try {
      const result: any = (await validateOtp({ user_id: ID, otp_token })) as any
      if (result.data?.status_code === statusCode.success) {
        // navigate to dashboard
        SetTokenToLocalStorage(jwt_token)
        setCurrentUser(result?.data?.data as UserModel)
      } else {
        // invalid code
        toastify({ type: 'error', message: result?.error?.data.error.message })
      }
    } catch (error: any) {
      toastify({ type: 'error', message: error })
    }
  }

  const onSubmitHandler: SubmitHandler<YupResolver> = (values) => {
    validate2fa(values.otp)
  }

  return (
    <div className='container'>
      <h3
        style={{
          ...customStyles.section_heading
        }}
      >
        Verify the Authentication Code
      </h3>
      <form onSubmit={handleSubmit(onSubmitHandler)} className='container'>
        <h2
          style={{
            ...customStyles.section_description
          }}
        >
          Two-Factor Authentication
        </h2>
        <p className='text-center text-sm'>
          Open the two-step verification app on your mobile device to get your verification code.
        </p>
        <input
          {...register('otp')}
          className={clsx('form-control bg-transparent')}
          placeholder='Authentication Code'
        />
        <p
          style={{
            ...customStyles.error_message
          }}
        >
          {errors.otp ? errors.otp.message : null}
        </p>
        <div className='d-grid mb-5'>
          <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
            {isLoading
              ? (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
                )
              : (
              <span className='indicator-label'>Continue</span>
                )}
          </button>
        </div>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button>
        </Link>
      </form>
    </div>
  )
}

export default Validate2faPage

const customStyles = {
  section_heading: {
    textAlign: 'center' as TextAlign,
    fontSize: '1.875rem',
    fontWeight: 'bold',
    color: '#222'
  },
  section_description: {
    color: '#6b7280',
    fontWeight: '600',
    fontSize: '1.25rem',
    textAlign: 'center' as TextAlign
  },
  error_message: {
    marginTop: '0.5rem',
    fontSize: '0.75rem',
    color: '#dc2626'
  }
}
