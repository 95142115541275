import { useNavigate } from 'react-router-dom'
import { toastify } from '../customHooks/Toast'
import { JWT_ERROR_MESSAGE, SESSION_EXPIRED_MESSAGE, RemoveTokenFromLocalStorage } from '../utilities/utility'
import { type RTKAPIResponse, useAuth } from 'src/app/modules/auth'
import { store } from 'src/app/redux/store'
import { authApi } from 'src/app/redux/Slices/authApi'
function CustomLogoutHook () {
  const navigate = useNavigate()
  const { setCurrentUser } = useAuth()
  function handleApiResponse (error: RTKAPIResponse, isError: boolean, showToast?: boolean) {
    const message = error?.data?.error?.message || error?.data?.message
    if (error?.data?.status_code === 401) {
      RemoveTokenFromLocalStorage()
      toastify({ type: 'error', message: SESSION_EXPIRED_MESSAGE })
      setCurrentUser(undefined)
      store.dispatch(authApi.util.resetApiState())
      navigate('/auth')
    } else if (message && isError && showToast) {
      toastify({ type: 'error', message })
    }
  }

  return handleApiResponse
}

export default CustomLogoutHook
