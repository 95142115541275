// import { Box, Skeleton } from '@mui/material';
import React from 'react'
import { BeatLoader } from 'react-spinners'

const FormSkeletonLoading = () => {
  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: '50%',
          left: '50%'
        }}
      >
        <BeatLoader color='#000' />
      </div>
    </>
  )
}

export default FormSkeletonLoading
