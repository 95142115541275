import { useCallback } from 'react'
import type * as yup from 'yup'
import { type YupResolver } from '../../modules/auth/core/_models'
const useYupValidationResolver = (Schema: yup.Schema<any>) =>
  useCallback(
    async (data: YupResolver) => {
      try {
        const values = await Schema.validate(data, {
          abortEarly: false
        })

        return {
          values,
          errors: {}
        }
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? 'validation',
                message: currentError.message
              }
            }),
            {}
          )
        }
      }
    },
    [Schema]
  )

export default useYupValidationResolver
