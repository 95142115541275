import { combineReducers, configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { authApi } from './Slices/authApi'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
// const rootReducer = combineReducers({
//   [authApi.reducerPath]: authApi.reducer,
// });

// const persistConfig = {
//   key: 'root',
//   storage,
// }

// const persistedReducer = persistReducer(persistConfig, rootReducer)

// export const store = configureStore({
//   reducer: persistedReducer,
//   devTools: true,
//   middleware: getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware),
// });
const rootReducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer
})

const persistConfig = {
  key: 'root',
  storage
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: getDefaultMiddleware({ serializableCheck: false }).concat(authApi.middleware)
})
export const persistor = persistStore(store)
