export const statusCode = {
  success: 200,
  create: 201,
  not_found: 404
}
export const loginMessage = {
  success: 'Login Successfully'
}
export const resendVerifyMessage = {
  toastMessage: 'Please check you email and Verify The email',
  heading: 'Email Verification',
  beforeVerifyInfo:
    'Your account has not been verified yet, and we kindly request you to complete the email verification process to activate your account. To proceed with the verification,Click on the Send button and you will receive another email to the address associated with your account. Please check your inbox and spam/junk folder for an email.You will find a verification link. Click on the link to complete the verification process',
  afterVerifyInfo: 'Please check your email, we have sent you a link to verify your Email.'
}
export const signupMessage = {
  success: 'Congratulations! You have Successfully Signed Up.'
}

export const forgetMessage = {
  toastMessage: 'Please Check Your Email, We have Sent you a Link to Reset your Password',
  heading: 'Forget your password?',
  beforeEmailInfo: 'Confirm your email and we will send you instructions to reset password.',
  afterEmailInfo: 'Please check your email, we sent you a link for reset your password.'
}

export const userProfileUpdate = {
  toastMessage: 'User Profile Updated Successfully'
}

export const listingInfo = {
  nodata: 'No data to display.'
}
export const NumberDelete = {
  message: 'Number Deleted Successfully'
}

export const mainNumberMessage = {
  infoText: ` In publishing and graphic design, Lorem ipsum is a placeholder
    text commonly used to demonstrate the visual form of a document
    or a typeface without relying on meaningful content.`
}

export const IsUSNumber = {
  message: 'Please enter a vaild US number'
}
export const UserUpdate = {
  message: 'User Details Update Successfully'
}

export const passwordChangeMessage = {
  success: 'Password change successfully',
  heading: 'Changed'
}
