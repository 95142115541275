import { Outlet } from 'react-router-dom'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, type StateContextValue } from './modules/auth'
import { store, persistor } from './redux/store'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createContext, useContext, useState, Suspense } from 'react'

const StateContext = createContext<StateContextValue>({
  allStates: {
    dragData: {},
    closestNode: {},
    currentNode: {},
    callflowObj: {}
  },
  setAllStates: () => ({
    dragData: {},
    closestNode: {},
    currentNode: {},
    callflowObj: {}
  })
})
const App = () => {
  const dragData = {}
  const closestNode = {}
  const currentNode = {}
  const callflowObj = {}

  // Create a single state object to hold all three states
  const [allStates, setAllStates] = useState({
    dragData,
    closestNode,
    currentNode,
    callflowObj
  })
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Suspense fallback={<LayoutSplashScreen />}>
          <StateContext.Provider value={{ allStates, setAllStates }}>
            <LayoutProvider>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LayoutProvider>
          </StateContext.Provider>
        </Suspense>
      </PersistGate>
    </Provider>
  )
}
export { App }
export function useStateContext () {
  return useContext(StateContext)
}
