import React, {
  type FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  type Dispatch,
  type SetStateAction
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { type AuthModel, type UserModel, type RTKAPIResponse } from './_models'
import * as authHelper from './AuthHelpers'
import { GetTokenFromLocalStorage } from '../../../common/utilities/utility'
import { type WithChildren } from '../../../../_metronic/helpers'
import { useLazyGetProfileQuery } from '../../../redux/Slices/authApi'

interface AuthContextProps {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {}
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const [user, setUser] = useState()
  const didRequest = useRef(false)
  const [updateProfile] = useLazyGetProfileQuery()
  const jwt_token = GetTokenFromLocalStorage()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const fetchData = async () => {
    const result: RTKAPIResponse = (await updateProfile({}, true)) as RTKAPIResponse
    if (result?.data?.data) {
      if (result.data.data.otp_enabled) {
        if (result.data.data.otp_verified) {
          setCurrentUser(result.data.data)
          setUser(result.data.data)
        }
      } else {
        setCurrentUser(result.data.data)
        setUser(result.data.data)
      }
    }
  }

  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (X_auth_token: string) => {
      try {
        if (!didRequest.current) {
          await fetchData()
        }
      } catch (error) {
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }
    if (jwt_token) {
      requestUser(jwt_token)
    } else {
      logout()
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
