import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { toastify } from '../../../common/customHooks/Toast'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useForm } from 'react-hook-form'
import { useLoginSchema } from '../../../common/validations/signin.validation'
import { useAuth } from '../core/Auth'
import { type UserModel, type RTKAPIResponse, type RTKAPIResponseWithToken } from '../core/_models'
import useYupValidationResolver from '../../../common/components/YupValidationResolver'
import { statusCode, loginMessage } from '../../../common/utilities/constantsMessages'
import { GetFirstIndex, GetTokenFromLocalStorage, SetTokenToLocalStorage } from '../../../common/utilities/utility'
import { USER_NOT_EXIST } from '../../../common/utilities/constant'
import CircleTickSvg from '../../../common/svg/circleTickSVG'

import {
  useLoginMutation,
  useLoginUsingTokenMutation,
  useLazyCompanyDetailQuery
} from '../../../redux/Slices/authApi'
import MyModal from './VerifyModal'
import Spinner from '../../../common/components/Spinner'
import { FormattedMessage, useIntl } from 'react-intl'
import { intl } from 'src/i18n'
export function Login () {
  const loginSchema = useLoginSchema()
  const resolver = useYupValidationResolver(loginSchema)
  const { setCurrentUser } = useAuth()
  const [urlDomain, setUrlDomain] = useState('')
  const [ID, setId] = useState('')
  const firstElement = GetFirstIndex()
  const [info, setInfo] = useState('')
  const [jwt_token, setJwtToken] = useState('')
  const [showModal, setShowModal] = useState(false)
  const [userEmail, setUserEmail] = useState('')
  const navigate = useNavigate()
  const handleCloseModal = () => {
    setShowModal(false)
  }
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm({ resolver })

  const [login, { isLoading: isLoadingLogin, error: loginError }] = useLoginMutation()

  const [
    loginUsingToken,
    { isLoading: isLoadingToken, data: TokenLoginData, error: loginTokenError }
  ] = useLoginUsingTokenMutation()

  const [getCompanyDetail] = useLazyCompanyDetailQuery()

  const onSubmit = async () => {
    const formValues = getValues()
    const email = formValues.email
    const password = formValues.password
    if (email) setUserEmail(email)
    const response: RTKAPIResponseWithToken = (await login({
      email,
      password,
      ID
    })) as RTKAPIResponseWithToken
    const result = response.data
    if (result?.data?.org_id) {
      const user = result?.data
      if (user) {
        if (user?.org_id !== ID && firstElement !== 'app') {
          toastify({ type: 'error', message: USER_NOT_EXIST })
          return
        }
      }
      if (response?.data?.data?.otp_enabled) {
        // navigate to validate otp page
        navigate('/auth/2FA', { state: { token: response.data.authToken } })
      } else {
        // navigate to dashboard
        SetTokenToLocalStorage(response.data?.authToken!)
        setCurrentUser(user as UserModel)
      }
      if (result?.message) toastify({ type: 'success', message: result?.data?.message })
    }
  }

  const fetchData = async (domain: string) => {
    try {
      const params = {
        domain
      }
      const compData: RTKAPIResponse = (await getCompanyDetail(params)) as RTKAPIResponse
      if (compData) {
        const result = compData.data
        if (result?.status_code === statusCode.success) {
          setId(result?.data?.id)
        }
      }
    } catch (err: any) { }
  }

  const loginTokenFunc = async (token: string) => {
    const result: RTKAPIResponse = (await loginUsingToken(token)) as RTKAPIResponse
    if (result?.data?.status_code === statusCode.success) {
      toastify({ type: 'success', message: loginMessage.success })
    }
  }

  useEffect(() => {
    const tokenData: RTKAPIResponse = TokenLoginData as RTKAPIResponse
    const userFromToken = tokenData?.data
    if (userFromToken) {
      setCurrentUser(userFromToken as UserModel)
      SetTokenToLocalStorage(jwt_token)
    }
  }, [TokenLoginData])

  useEffect(() => {
    const typedLoginError: RTKAPIResponse = loginError as RTKAPIResponse
    const message = typedLoginError?.data?.error?.message
    const detail = typedLoginError?.data?.error?.detail
    if (message) {
      toastify({ type: 'error', message })
    }
    if (detail === 'not-verified') {
      setShowModal(true)
    }
  }, [loginError])

  useEffect(() => {
    const typedLoginTokenError: RTKAPIResponse = loginTokenError as RTKAPIResponse
    if (typedLoginTokenError?.data?.message) {
      toastify({ type: 'error', message: typedLoginTokenError.data.message })
    }
  }, [loginTokenError])

  useEffect(() => {
    try {
      const url = window.location.href
      const searchParams = new URLSearchParams(new URL(url).search)
      const token = searchParams.get('token')
      if (token) setJwtToken(token)
      const localToken = GetTokenFromLocalStorage()
      if (!localToken) {
        if (token) {
          loginTokenFunc(token)
        }
      }
      const parsedUrl = new URL(url)
      const domain = parsedUrl.hostname
      setUrlDomain(parsedUrl.hostname)
      const hostname = parsedUrl.hostname.split('.')[0]
      if (hostname !== 'app') fetchData(domain)
    } catch (error: any) { }
  }, [])

  return (
    <>
      {/* isLoadingLogin || isLoadingToken || companyDetailLoading || organizationLoading ? ( */}
      {isLoadingToken
        ? (
        <Spinner />
          )
        : (
        <>
          <form
            className='form w-100'
            noValidate
            id='kt_login_signin_form'
            onSubmit={handleSubmit(onSubmit)}
          >
            <div></div>
            <div className='text-center mb-11'>
              <h1 className='text-dark fw-bolder mb-3'>
                <FormattedMessage id='AUTH.LOGIN.BUTTON' />
              </h1>
              <div className='text-gray-500 fw-semibold fs-6'>
                <FormattedMessage id='AUTH.GENERAL.TEXT' />
              </div>
            </div>
            <div className='row g-3 mb-9'>
              <div className='col-md-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
                    className='h-15px me-3'
                  />
                  <FormattedMessage id='AUTH.GENERAL.GOOGLE' />
                </a>
              </div>
              <div className='col-md-6'>
                <a
                  href='#'
                  className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
                >
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
                    className='theme-light-show h-15px me-3'
                  />
                  <img
                    alt='Logo'
                    src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
                    className='theme-dark-show h-15px me-3'
                  />
                  <FormattedMessage id='AUTH.GENERAL.APPLE' />
                </a>
              </div>
            </div>
            <div className='separator separator-content my-14'>
              <span className='w-125px text-gray-500 fw-semibold fs-7'>
                <FormattedMessage id='AUTH.GENERAL.EMAIL' />
              </span>
            </div>

            {info.length > 1 && (
              <>
                <div className='text-center mb-11'>
                  <CircleTickSvg height={22} width={22} color={'#00C851'} />
                  <div className='text-black-500 fw-semibold fs-6 '>{info}</div>
                </div>
              </>
            )}

            <div className='fv-row mb-3'>
              <label className='form-label fs-6 fw-bolder text-dark'>
                <FormattedMessage id='AUTH.GENERAL.TEXT.EMAIL' />
              </label>
              <input
                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TEXT.EMAIL' })}
                type='email'
                autoComplete='off'
                {...register('email', {})}
                className={clsx('form-control bg-transparent')}
              />
            </div>
            {errors?.email?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors?.email?.message}
              </p>
            )}
            <div className='fv-row mb-3'>
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>
                <FormattedMessage id='AUTH.GENERAL.TEXT.PASSWORD' />
              </label>
              <input
                type='password'
                placeholder={intl.formatMessage({ id: 'AUTH.GENERAL.TEXT.PASSWORD' })}
                autoComplete='off'
                {...register('password', {})}
                className={clsx('form-control bg-transparent')}
              />
            </div>
            {errors?.password?.message && (
              <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
                {errors.password.message}
              </p>
            )}
            <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8'>
              <div />
              {/* {showVerify && (
            <div onClick={handleVerifyEmail} className='link-primary cursor-pointer'>
              Verify Email
            </div>
          )}
          <div /> */}

              {/* begin::Link */}
              <Link to='/auth/forgot-password' className='link-primary float-end'>
                <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
              </Link>
            </div>
            <div className='d-grid mb-10'>
              <button type='submit' id='kt_sign_in_submit' className='btn btn-primary'>
                {isLoadingLogin || isLoadingToken
                  ? (
                  <span className='indicator-progress' style={{ display: 'block' }}>
                    <FormattedMessage id='AUTH.GENERAL.TEXT.WAIT' />
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                    )
                  : (
                  <span className='indicator-label'>
                    <FormattedMessage id='AUTH.LOGIN.TEXT.CONTINUE' />
                  </span>
                    )}
              </button>
            </div>
            <div className='text-gray-500 text-center fw-semibold fs-6'>
              <FormattedMessage id='AUTH.LOGIN.TEXT.MEMBER' />{' '}
              <Link to='/auth/registration' className='link-primary'>
                <FormattedMessage id='AUTH.GENERAL.SIGNUP_BUTTON' />
              </Link>
            </div>
          </form>
          {userEmail && (
            <MyModal showModal={showModal} handleCloseModal={handleCloseModal} email={userEmail} />
          )}
        </>
          )}
    </>
  )
}
