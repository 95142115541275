import * as Yup from 'yup'
import { emailFormat } from '../utilities/constant'
import { useIntl } from 'react-intl'
export const UserForgotPasswordSchema = () => {
  const intl = useIntl()
  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL.WRONG' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX' }))
      .required(intl.formatMessage({ id: 'VALIDATION.EMAIL.TEXT.REQUIRED' }))
  })
  return forgotPasswordSchema
}
