import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faShare,
  faUser,
  faPhone,
  faVoicemail,
  faSitemap,
  faBan,
  faInfo,
  faCircle,
  faMusic,
  faUsers,
  faGripHorizontal,
  faPuzzlePiece,
  faEllipsisV,
  faPlus,
  faShareNodes,
  faGlobe,
  faHeadphonesSimple,
  faMicrophone,
  faTrash,
  faCalendarWeek,
  faMessage,
  faTrashCan,
  faPenToSquare,
  faCirclePlay,
  faCircleStop,
  faGreaterThan,
  faList,
  faFolderMinus,
  faRepeat,
  faListUl
} from '@fortawesome/free-solid-svg-icons'

library.add(
  faShare,
  faUser,
  faPhone,
  faVoicemail,
  faSitemap,
  faBan,
  faInfo,
  faCircle,
  faMusic,
  faUsers,
  faGripHorizontal,
  faPuzzlePiece,
  faEllipsisV,
  faPlus,
  faShareNodes,
  faGlobe,
  faHeadphonesSimple,
  faMicrophone,
  faTrash,
  faCalendarWeek,
  faMessage,
  faTrashCan,
  faPenToSquare,
  faCirclePlay,
  faCircleStop,
  faGreaterThan,
  faList,
  faFolderMinus,
  faRepeat,
  faListUl
)
