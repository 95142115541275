import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { UserForgotPasswordSchema } from '../../../common/validations/forgot.validation'
import useYupValidationResolver from '../../../common/components/YupValidationResolver'
import { useForm } from 'react-hook-form'
import { useForgetPasswordMutation } from '../../../redux/Slices/authApi'
import { statusCode, forgetMessage } from '../../../common/utilities/constantsMessages'
import { toastify } from '../../../common/customHooks/Toast'
import { type RTKAPIResponse } from '../core/_models'
import { FormattedMessage } from 'react-intl'
export function ForgotPassword () {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const forgotasswordSchema = UserForgotPasswordSchema()
  const resolver = useYupValidationResolver(forgotasswordSchema)
  const [forgetPassword, { error: forgetError }] = useForgetPasswordMutation()
  const navigate = useNavigate()
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues
  } = useForm({ resolver })
  const onSubmit = async () => {
    try {
      const formValues = getValues()
      const value = { email: formValues.email }
      const result: RTKAPIResponse = (await forgetPassword(value)) as RTKAPIResponse
      if (result?.data?.status_code === statusCode.success) {
        setHasErrors(false)
        toastify({
          type: 'success',
          message: forgetMessage.toastMessage
        })
        navigate('/auth')
      }
    } catch (err: any) {}
  }

  useEffect(() => {
    const error: RTKAPIResponse = forgetError as RTKAPIResponse
    if (error?.data?.error?.message) {
      setHasErrors(true)
      toastify({ type: 'error', message: error?.data?.error?.message })
    }
  }, [forgetError])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark fw-bolder mb-3'>
          <FormattedMessage id='AUTH.GENERAL.FORGOT_BUTTON' />
        </h1>
        <div className='text-gray-500 fw-semibold fs-6'>
          <FormattedMessage id='FORGOT.PASSWORD.TEXT.DESCRIPTION' />
          <FormattedMessage id='FORGOT.PASSWORD.TEXT.DESCRIPTION' />
        </div>
      </div>
      {hasErrors === true && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>
            <FormattedMessage id='FORGOT.PASSWORD.ERROR.TRUE' />
          </div>
        </div>
      )}

      {hasErrors === false && (
        <div className='mb-10 bg-light-info p-8 rounded'>
          <div className='text-info'>
            <FormattedMessage id='FORGOT.PASSWORD.ERROR.FALSE' />
          </div>
        </div>
      )}
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          <FormattedMessage id='AUTH.GENERAL.TEXT.EMAIL' />
        </label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...register('email', {})}
          className={clsx('form-control bg-transparent')}
        />
        {errors?.email?.message && (
          <p className='error-message' style={{ color: 'red', fontSize: 13 }}>
            {errors?.email?.message}
          </p>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>
            <FormattedMessage id='AUTH.GENERAL.SUBMIT.BUTTON' />
          </span>
          {loading && (
            <span className='indicator-progress'>
              <FormattedMessage id='AUTH.GENERAL.TEXT.WAIT' />
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
          >
            <FormattedMessage id='AUTH.GENERAL.CANCEL.BUTTON' />
          </button>
        </Link>{' '}
      </div>
    </form>
  )
}
