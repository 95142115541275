import * as Yup from 'yup'
import { numberRegex, emailFormat } from '../utilities/constant'

import { useIntl } from 'react-intl'
export const useRegistrationSchema = () => {
  const intl = useIntl()
  const registrationSchema = Yup.object().shape({
    fullname: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX' }))
      .required(intl.formatMessage({ id: 'VALIDATION.FULLNAME.REQUIRED' })),
    email: Yup.string()
      .email(intl.formatMessage({ id: 'VALIDATION.EMAIL.WRONG' }))
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX' }))
      .required(intl.formatMessage({ id: 'VALIDATION.EMAIL.TEXT.REQUIRED' })),
    password: Yup.string()
      .max(16, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MAX' }))
      .min(8, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MIN' }))
      .matches(/[a-z]+/, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCHES.LOWERCASE' }))
      .matches(/[A-Z]+/, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCHES.UPPERCASE' }))
      .matches(/[@$!%*#?&]+/, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCHES.SPECIALCHAR' }))
      .matches(/\d+/, intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCHES.NUMBER' }))
      .required(intl.formatMessage({ id: 'VALIDATION.PASSWORD.TEXT.REQUIRED' })),
    company_name: Yup.string().required(intl.formatMessage({ id: 'VALIDATION.COMPANY.REQUIRED' })),
    confirm_password: Yup.string()
      .min(3, intl.formatMessage({ id: 'VALIDATION.MIN' }))
      .max(50, intl.formatMessage({ id: 'VALIDATION.MAX' }))
      .required(intl.formatMessage({ id: 'VALIDATION.CPASSWORD.REQUIRED' }))
      .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'VALIDATION.PASSWORD.MATCH' })),
    personal_phone: Yup.string()
      .matches(numberRegex, intl.formatMessage({ id: 'VALIDATION.NUMBER.VALID' }))
      .required(intl.formatMessage({ id: 'VALIDATION.NUMBER.REQUIRED' }))
    // acceptTerms: Yup.bool().required(intl.formatMessage({id: 'VALIDATION.CONDITION.ACCEPT'})),
    // accept_terms: Yup.boolean().test(
    //       'accept_terms',
    //       'You must accept the terms and conditions',
    //       (value) => value === true
    //     ),
  })
  return registrationSchema
}
