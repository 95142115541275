const palette = {
  gray: {
    0: '#f7f7f7',
    100: '#e6e6e6',
    200: '#bfbebe',
    300: '#989898',
    400: '#777777',
    500: '#404040',
    600: '#343A40'
  },
  primary: {
    mediumLight: '#1fbdef',
    medium: '#43b4e7',
    dark: '#0565d8',
    light: '#3d15de',
    extraLight: '#fafafc',
    lightBlueShade: '#4da1c0',
    // appBackground:"#f1f5f9",
    appBackground: '#f8fafd',
    sidebarBackground: '#111827'
  },
  common: {
    white: '#ffffff',
    black: '#000000',
    blue: '#3F51B5',
    gray: '#424040',
    lightBlack: '#383838',
    lightBlue: '#05A9D9',
    lightGreen: '#AFE5B4',
    iconEnbale: '#19d332',
    iconDisable: '#878787',
    iconDisable1: '#bdbdbd',
    success: '#6BCD1F',
    danger: '#F1416c',
    lightGray: '#cccccc',
    lightDanger: '#f7928c',
    lightTinyBlue: '#E8F4FF',
    shadedGrey: '#87939e',
    saveBtn: '#4CAF50'
  },
  red: {
    100: '#e41346',
    200: '#f44336',
    300: '#CC0032'
  },
  yellow: {
    200: '#FFF246'
  },
  orange: {
    100: '#FBFCD8',
    200: '#F1F1D2',
    300: '#EEE5D8',
    400: '#EEE5D8'
  },
  green: {
    100: '#0be40b',
    200: '#00CC00'
  },
  antiquewhite: {
    100: '#faebd7'
  },
  skyblue: {
    100: '#CBF4ED',
    300: '#D3E7FF'
  },
  pink: {
    100: '#E3E3E3',
    200: '#D1DBE9'
  }
}

export default palette
