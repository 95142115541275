import { v4 as uuidv4 } from 'uuid'
import { toastify } from '../customHooks/Toast'
import { type RTKAPIResponse, useAuth } from 'src/app/modules/auth'
import { format, parse } from 'date-fns'
import {
  DEVICE,
  DEVICE_ID,
  MAIN_MENU,
  MAIN_MENU_ID,
  MENU,
  MENU_ID,
  PLAY_MEDIA,
  PLAY_MEDIA_ID,
  QUEUE,
  QUEUE_ID,
  RECORD_START,
  RECORD_START_ID,
  RECORD_STOP,
  RECORD_STOP_ID,
  REPEAT_MENU,
  REPEAT_MENU_ID,
  RING_GROUP,
  RING_GROUP_ID,
  TIME_OF_DAY,
  TIME_OF_DAY_ID,
  USER,
  USER_ID,
  VOICE_MAIL,
  VOICE_MAIL_ID
} from './constant'
export const GetDomainFromUrl = () => {
  const hostnameArray = window.location.hostname.split('.')
  const numberOfSubdomains = hostnameArray.length - 2
  return hostnameArray.length === 2
    ? window.location.hostname
    : hostnameArray.slice(numberOfSubdomains).join('.')
}
export const getUID = () => {
  return uuidv4().split('-').at(0)
}

export const GetFirstIndex = () => {
  const url = window.location.href
  const parsedUrl = new URL(url)
  return parsedUrl.hostname.split('.')[0]
}

export const GetTokenFromLocalStorage = () => {
  return localStorage.getItem('X_auth_token')
}
export const SetTokenToLocalStorage = (token: string) => {
  localStorage.setItem('X_auth_token', token)
}
export const RemoveTokenFromLocalStorage = () => {
  localStorage.removeItem('X_auth_token')
}
export const getIds = (array: any) => {
  if (array && Array.isArray(array)) {
    return array.map((item) => item.value)
  }
  return [] // Return an empty array if the input array is null or not an array
}

// strategyList for queue and ringgroup
const strategyList = [
  { id: getUID(), label: 'Ring all', value: 'ring-all' },
  { id: getUID(), label: 'Round-Robin', value: 'round-robin' },
  {
    id: getUID(),
    label: 'Round-Robin With Memory',
    value: 'round-robin-with-memory'
  }
]

export const getStrategyList = () => strategyList

export const JWT_ERROR_MESSAGE = 'invalid token'
export const SESSION_EXPIRED_MESSAGE = 'Session Expired. Please log in again to continue.'
export const showErrorToastIfMessageExists = (error: RTKAPIResponse, isError: boolean) => {
  const message = error?.data?.error?.message || error?.data?.message
  if (message && isError) {
    toastify({ type: 'error', message })
  }
}

export function formatTime (timeArr = []) {
  const hour = timeArr[0]
  const minute: any = timeArr[1]
  let ampm = ''

  if (hour < 12) {
    ampm = 'AM'
  } else {
    ampm = 'PM'
  }

  let formattedHour = hour % 12 // Convert to 12-hour format
  if (formattedHour === 0) {
    formattedHour = 12 // 12 midnight or noon should not be 0
  }

  const formattedMinute = minute.toString().padStart(2, '0') // Add leading 0 if necessary

  return `${formattedHour}:${formattedMinute} ${ampm}`
}

export const convertDatesToMMDD = (datesArray: any) => {
  return datesArray?.map((date: any) => {
    const formattedDate = format(new Date(date), 'MM/dd')
    return formattedDate
  })
}

export const convertDatesToMMDDYY = (datesArray: any) => {
  return datesArray?.map((date: any) => {
    const parsedDate = parse(date, 'MM/dd', new Date())
    const formattedDate = format(parsedDate, 'yyyy-MM-dd')
    return formattedDate
  })
}

export function convertToIntegerArray (timeString: any) {
  const [hoursString, minutesString] = timeString.split(':')
  const hours = parseInt(hoursString, 10)
  const minutes = parseInt(minutesString, 10)

  return [hours, minutes]
}

export const formatTimeArray = (timeArray: any) => {
  if (timeArray != null) {
    const hours = timeArray[0]
    const minutes = timeArray[1]
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }
}
const listOfDnD = [
  { label: DEVICE, id: DEVICE_ID, type: 'metaNode', icon: 'phone', deleteIcon: 'faClose' },
  { label: PLAY_MEDIA, id: PLAY_MEDIA_ID, type: 'metaNode', icon: 'music', deleteIcon: 'faClose' },
  { label: MENU, id: MENU_ID, type: 'metaNode', icon: 'grip-horizontal', deleteIcon: 'faClose' },
  { label: USER, id: USER_ID, type: 'metaNode', icon: 'user', deleteIcon: 'faClose' },
  {
    label: RING_GROUP,
    id: RING_GROUP_ID,
    type: 'metaNode',
    icon: 'users',
    deleteIcon: 'faClose'
  },
  { label: QUEUE, id: QUEUE_ID, type: 'metaNode', icon: 'ellipsis-v', deleteIcon: 'faClose' },
  { label: VOICE_MAIL, id: VOICE_MAIL_ID, type: 'metaNode', icon: 'voicemail', deleteIcon: 'faClose' },
  {
    label: TIME_OF_DAY,
    id: TIME_OF_DAY_ID,
    type: 'specialMetaNode',
    icon: 'calendar-week',
    deleteIcon: 'faClose'
  },
  {
    label: RECORD_START,
    id: RECORD_START_ID,
    type: 'specialMetaNode',
    icon: 'circle-play',
    deleteIcon: 'faClose'
  },
  {
    label: RECORD_STOP,
    id: RECORD_STOP_ID,
    type: 'specialMetaNode',
    icon: 'circle-stop',
    deleteIcon: 'faClose'
  },
  {
    label: REPEAT_MENU,
    id: REPEAT_MENU_ID,
    type: 'specialMetaNode',
    icon: 'repeat'
  },
  {
    label: MAIN_MENU,
    id: MAIN_MENU_ID,
    type: 'specialMetaNode',
    icon: 'list-ul'
  }
]
export const getListOfDnD = () => listOfDnD

export const getClosestNode = (allNodes: any, newNode: any) => {
  const x1 = newNode.position.x
  const y1 = newNode.position.y

  const closestObj = allNodes
    .map((node: any) => {
      const x2 = node.position.x
      const y2 = node.position.y
      const y = x2 - x1
      const x = y2 - y1
      return { id: node.id, distance: Math.ceil(Math.sqrt(x * x + y * y)) }
    })
    .sort((a: any, b: any) => a.distance - b.distance)[0]

  return closestObj
}
export const checkChild = (allEdges: any, closedNode: any) => {
  if (
    closedNode.id.includes('time-of-day')
  ) {
    return { node: 'time-of-day', isPlaceable: true }
  } else if (
    closedNode.id.includes('menu-base')
  ) {
    return { node: 'menu-base', isPlaceable: true }
  } else if (
    closedNode.id.includes('repeat-menu')
  ) {
    return { node: 'repeat-menu', isPlaceable: false }
  } else if (
    closedNode.id.includes('main-menu')
  ) {
    return { node: 'main-menu', isPlaceable: false }
  } else {
    return { node: 'other', isPlaceable: allEdges.every((node: any) => node.source !== closedNode.id) }
  }
}
export const dialDights = [
  '#',
  '*',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  'timeout'
]

export const CDR_CallStatus = [
  { id: '', value: 'All' },
  { id: '1', value: 'Answer' },
  { id: '2', value: 'Busy' },
  { id: '3', value: 'No Answer' },
  { id: '4', value: 'Error' },
  { id: '5', value: 'Answer Timeout' }
]

export const CDR_Direction = [
  { id: 'all', value: 'All' },
  { id: 'inbound', value: 'Inbound' },
  { id: 'outbound', value: 'Outbound' }
]

export const getTranformModule = (module: any) => {
  return {
    ...(module?.name && { name: module?.name }),
    ...(module?.first_name && {
      name: `${module?.first_name} ${module?.last_name}`
    })
  }
}

const dialNumbers = [
  { id: '#', value: '#' },
  { id: '*', value: '*' },
  { id: '0', value: '0' },
  { id: '1', value: '1' },
  { id: '2', value: '2' },
  { id: '3', value: '3' },
  { id: '4', value: '4' },
  { id: '5', value: '5' },
  { id: '6', value: '6' },
  { id: '7', value: '7' },
  { id: '8', value: '8' },
  { id: '9', value: '9' },
  { id: 'timout', value: 'timeout' }
]
export const getDialNumbers = () => dialNumbers

export const getUIDFull = () => {
  return uuidv4()
}
export const specialModuleType = {
  queue: 0,
  device: 1,
  user: 2,
  ring_group: 3,
  voicemail: 4,
  record_start: 5,
  record_stop: 6,
  play_media: 7,
  TIME_OF_DAY: 8,
  repeat_menu: 10,
  main_menu: 11
}
export const getNodeIcon = (label: string) => {
  return {
    ...(label === DEVICE && { icon: 'phone' } && { deleteIcon: 'faClose' }),
    ...(label === MENU && { icon: 'grip-horizontal' } && { deleteIcon: 'faClose' }),
    ...(label === USER && { icon: 'user' } && { deleteIcon: 'faClose' }),
    ...(label === PLAY_MEDIA && { icon: 'music' } && { deleteIcon: 'faClose' }),
    ...(label === RING_GROUP && { icon: 'users' } && { deleteIcon: 'faClose' }),
    ...(label === QUEUE && { icon: 'ellipsis-v' } && { deleteIcon: 'faClose' }),
    ...(label === VOICE_MAIL && { icon: 'voicemail' } && { deleteIcon: 'faClose' })
  }
}
export const allOtherTime = {
  id: '_',
  name: 'all other time',
  time_zone: '-',
  on: [0, 1, 2, 3, 4, 5, 6],
  time: {
    start: [0, 0],
    end: [0, 0]
  },
  org_id: '633aacf8de47beff022bc90b'
}
